import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../axios/axiosInterceptor';

export default function Header() {
  const [categoryList, setCategoryList] = useState([]);
  const [navOpen, setNavOpen] = useState(false);
  const [categoryListTotal, setCategoryListTotal] = useState(0);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = ()=>{
    axiosInstance.get('Category/view-category.php')
    .then((response) => {
      const res =  response.data;
      console.log('res',res);
      if(res.code === 100){
        setCategoryListTotal(res.count)
        setCategoryList(res.data)
      }
    })
    .catch((error) => {
      // Handle any errors
    });
  } 

 
  return (
    <header id="header" className="header header-sticky-smart disable-transition-all z-index-5">
     
      <div className="sticky-area">
      <div style={{background: "#ed1a25", height: "15px"}}>

</div>
        <div className="main-header nav navbar bg-body navbar-light navbar-expand-md py-xl-0">
          <div className="container-wide container flex-nowrap">
          <a href="/" className="navbar-brand px-md-8 py-4" style={{paddingTop: "0px !important",marginTop: "-10px"}}>
              <img className="logo-main" src="/assets/images/others/logo.png" alt="logo" width={100} />
            </a> 
            <div className="f-flex jusstify-content-end">
              <button className="navbar-toggler align-self-center  border-0 shadow-none px-0 canvas-toggle p-4" onClick={()=>setNavOpen(!navOpen)} type="button"  data-bs-target="#offCanvasNavBar" aria-controls="offCanvasNavBar" aria-expanded="false" aria-label="Toggle Navigation">
                <span className="fs-24 toggle-icon"></span>
              </button>
            </div>
            <div className="d-flex">

              {/* On click above navbar-toogler toggle class in navbar-nav "show-nav"  */}
              <ul className={`navbar-nav ${navOpen? "show-nav": ""}`}>
                <li className="nav-item transition-all-xl-1 py-11 px-5 me-xxl-12 me-xl-10 dropdown dropdown-hover dropdown-fullwidth">
                  <a className="nav-link d-flex justify-content-between position-relative py-0 px-0 text-uppercase fw-bold ls-1 fs-16px" href="/">Home</a>

                </li>
                <li className="nav-item transition-all-xl-1 py-11 px-5 me-xxl-12 me-xl-10 dropdown dropdown-hover dropdown-fullwidth position-static">
                  <a className="nav-link d-flex justify-content-between position-relative py-0 px-0 text-uppercase fw-bold ls-1 fs-16px" href="/aboutus">About us</a>
                </li>
                <li className="nav-item transition-all-xl-1 py-11 px-5 me-xxl-12 me-xl-10 dropdown dropdown-hover dropdown-fullwidth">
                  <a href="/products" className="nav-link d-flex justify-content-between position-relative py-0 px-0 text-uppercase fw-bold ls-1 fs-16px" >Products</a>
                  <ul className={`dropdown-menu py-6 ${navOpen? "d-block": ""}`} aria-labelledby="menu-item-pages">
                       {categoryList.map((cate)=>{
                         return(<li>
                            <a className="dropdown-item pe-6 border-hover" href={`/category-list/${cate.category_alias}`}>
                                <span className="border-hover-target">
                                  {cate.category_name}
                                </span>
                            </a>
                        </li>)
                       })}
                    </ul>
                </li>  
                <li className="nav-item transition-all-xl-1 py-11  px-5 me-xxl-12 me-xl-10 dropdown dropdown-hover dropdown-fullwidth">
                  <a className="nav-link d-flex justify-content-between position-relative py-0 px-0 text-uppercase fw-bold ls-1 fs-16px" href="/contact-us"> Contact Us</a>
                </li> 
              </ul>
            </div>
           
          </div>
        </div>
      </div>
    </header>
  );
}
