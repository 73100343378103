
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import './App.scss';
import CategoryList from "./pages/CategoryList";
import Contactus from "./pages/Contactus";
import MainCategoryList from "./pages/MainCategoryList";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route excect path="/home" element={<Home/>} />
          <Route excect path="/aboutus" element={<Aboutus/>} />
          <Route path="/products" element={<MainCategoryList/>} />
          <Route path="/category-list" element={<CategoryList/>} />
          <Route path="/category-list/:slug" element={<CategoryList/>} />
          <Route excect path="/contact-us" element={<Contactus/>} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
