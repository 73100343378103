import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Footer from '../components/Footer';
import Header from '../components/Header'; 
import Breadcrumbs from '../components/Breadcrumbs';
import {useParams} from 'react-router-dom';
import axiosInstance from "../axios/axiosInterceptor";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CategoryList() {
  const {slug} = useParams();
  const closeButtonRef = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
const [categoryListTotal, setCategoryListTotal] = useState(0);

useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    axiosInstance
      .get("Category/view-category.php")
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (res.code === 100) {
          setCategoryListTotal(res.count);
          setCategoryList(res.data);
          setloading(false)
        }
      })
      .catch((error) => {
        // Handle any errors
        setloading(false)
        
      });
  };

  const initialInquiry = {
    name: "",
    phone: "",
    email: "",
    product: "",
    variation: "",
    city: ""
  }
  useEffect(()=>{
    getProductList();
  },[slug])
  const [productList, setProductList] = useState([]);
  const [loading, setloading] = useState(true);
  const [productListTotal, setProductListTotal] = useState(0);
  const [selectedProductIndex, setselectedProductIndex] = useState(-1);
  const [inquiryData, setinquiryData] = useState(initialInquiry);

  const InputHandler = (e,identify)=>{
    setinquiryData({...inquiryData, [identify]: e.target.value})
  }

  const getProductList = ()=>{
    axiosInstance
      .post("Product/view-product.php",{
        "category_alias": slug
      })
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (res.code === 100) {
          setProductListTotal(res.count);
          setProductList(res.data);
          setTimeout(() => {
            setloading(false)
          }, 200);
        }
      })
      .catch((error) => {
        // Handle any errors
        setTimeout(() => {
          setloading(false)
        }, 200);

      });
  }

  const submitInquiry = (e)=>{
    e.preventDefault();
    console.log('inquiryData',inquiryData);
    const finalData={
      table_name: "product_inquiry",
      is_image: "false",
      data:inquiryData
    }
    axiosInstance
      .post("General/add-data.php",finalData)
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
      })
      .catch((error) => {
        // Handle any errors

      });

  }

  return (
    <>
     <SkeletonTheme >
      <Header></Header>
      {/* <Breadcrumbs category={!loading && productListTotal > 0 && productList[0].category_name} />  */}
      <main id="content" className="wrapper layout-page list-page"> 
        <section className='category-list-wrapper py-10 bg-secondry phighlight py-m-5' style={{minHeight: "500px"}}>
          <div className="container container-xxl content ">
          <h3 className="pb-5 font24">{!loading && productListTotal > 0 && productList[0].category_name}</h3>
            <p>{!loading && productListTotal > 0 && productList[0].category_short_desc}</p>
            <div className="category-list"> 
            {console.log(productList)}
              {
                !loading && productListTotal > 0 ? productList.filter((data)=>data.is_active1 == 1).map((prod,index)=>{
                  return(
                    <div className="item"> 
                      <figure className='product-image '>
                        <a href="#" className='product-link'>
                        <img
                        onClick={()=>{setselectedProductIndex(index);setinquiryData({...inquiryData,product:productList[index].product_name, variation:productList[index].product_variations.split(',')[0]})}}
                        data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                        src={`${process.env.REACT_APP_RESOURCE_URL}product_image/${prod.product_image}`} alt="biryani-masala" width={250} />
                        </a>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name"
                      onClick={()=>{setselectedProductIndex(index);setinquiryData({...inquiryData,product:productList[index].product_name, variation:productList[index].product_variations.split(',')[0]})}}
                      data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                      style={{fontWeight: "bold"}}
                      >
                        {prod.product_name}
                      </div>  
                      {/* <div className="qty"> <b>QTY:</b> {prod.product_variations}</div> */}
                      {/* <button type="button" onClick={()=>{setselectedProductIndex(index);setinquiryData({...inquiryData,product:productList[index].product_name, variation:productList[index].product_variations.split(',')[0]})}}  className="w-100 py-3 btn btn-lg btn-primary btn-hover-bg-primary btn-hover-border-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                        Enquire Now
                      </button>   */}
                    </div>

                      {/* <ul className="list-inline fs-18px mb-0 category-social-media d-flex flex-column">
                        <li className="list-inline-item">
                            <a href="#" className='icon'><i className="fab fa-twitter" /></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#" className='icon'><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li className="list-inline-item ">
                            <a href="#" className='icon'><i className="fab fa-instagram" /></a>
                        </li>
                        <li className="list-inline-item me-4">
                            <a href="#" className='icon'><i className="fab fa-youtube" /></a>
                        </li>
                      </ul> */}
                  </div> 
                  )
                }):loading?
                <>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
              </>
            
                :<p className="text-center">No Products Found.</p>
              }
            </div>
          </div>


      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Enquiry Now</h5>
              <button  ref={closeButtonRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={submitInquiry}>
            <div className="modal-body">
              
                <div className="mb-8">
                  <label for="recipient-name" className="col-form-label sr-only">Name</label>
                  <input required type="text" onChange={(e)=>InputHandler(e,'name')} value={inquiryData.name && inquiryData.name} className="form-control" id="recipient-name" placeholder='Name' />
                </div> 
                <div className="mb-8">
                  <label for="recipient-name" className="col-form-label  sr-only">Phone Number</label>
                  <input required type="tel" pattern="[1-9]{1}[0-9]{9}" onChange={(e)=>InputHandler(e,'phone')}  value={inquiryData.phone && inquiryData.phone} className="form-control" id="recipient-name" placeholder='Phone Number'/>
                </div> 
                <div className="mb-8">
                  <label for="recipient-name" className="col-form-label  sr-only">Email</label>
                  <input required type="email" onChange={(e)=>InputHandler(e,'email')}  value={inquiryData.email && inquiryData.email} className="form-control" id="recipient-name" placeholder='Enter Email'/>
                </div> 
                <div className="mb-8">
                  <label for="recipient-name" className="col-form-label  sr-only">Product Name</label>
                  <input required type="text" readOnly value={(productListTotal > 0 && selectedProductIndex != -1)&& productList[selectedProductIndex].product_name} className="form-control" id="recipient-name" placeholder='Enter your mail'/>
                </div> 
                <div className="mb-8">
                  <label for="recipient-name" className="col-form-label  sr-only">Product Variation</label>
                  <select required onChange={(e)=>InputHandler(e,'variation')} value={inquiryData.variation && inquiryData.variation} className="form-control">
                    {
                      (productListTotal > 0 && selectedProductIndex != -1) &&

                      productList[selectedProductIndex].product_variations.split(',').map((vdata)=>
                    <option value={vdata}>{vdata}</option>

                      )
                    }
                  </select>
                </div> 
                <div className="">
                  <label for="recipient-name" className="col-form-label sr-only">City</label>
                  <input required type="text" onChange={(e)=>InputHandler(e,'city')} value={inquiryData.city && inquiryData.city}  className="form-control" id="recipient-name" placeholder='City' />
                </div> 
            </div>
            <div className="modal-footer pb-8"> 
              <button type="submit" className="btn btn-primary w-100">Send message</button>
            </div>
            </form>

          </div>
        </div>
      </div>
        </section>
        <section className='category-list-wrapper bg-secondry phighlight py-m-5 pb-9'>
          <div className="container container-xxl content">
          <h3 className="pb-5 font24 mb-7">Explore More</h3>
            <div className="category-list"> 
            
              {
                !loading && categoryListTotal > 0 ? (categoryList.map((cate,index)=>{
                  return(
                    <>
                    <div className="item"> 
                      <figure className='product-image '>
                        <a href={`/category-list/${cate.category_alias}`} className='product-link'>
                        <img
                       src={`${process.env.REACT_APP_RESOURCE_URL}category_icon/${cate.category_icon}`} alt="biryani-masala" width={250} />
                        </a>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name"
                      data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                      style={{fontWeight: "bold"}}
                      >
                        {cate.category_name}
                      </div>  
                    </div>
                  </div> 
                  </>
                  )
                  }))
                :loading?
                <>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
              </>
            
                :<p className="text-center">No Categories Found.</p>
              }
            </div>
          </div>


        </section>

      </main>
      <Footer></Footer>
      </SkeletonTheme>
    </>
  );
}
