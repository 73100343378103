import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axiosInstance from "../axios/axiosInterceptor";
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive'

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


export default function Home() {
  const initialInquiry = {
    name: "",
    phone: "",
    email: "",
    product: "",
    variation: "",
    city: ""
  }

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListTotal, setCategoryListTotal] = useState(0);
  const [productList, setProductList] = useState([]);
  const [loading, setloading] = useState(true);
  const [productListTotal, setProductListTotal] = useState(0);
  const [inquiryData, setinquiryData] = useState(initialInquiry);
  const [selectedProductIndex, setselectedProductIndex] = useState(-1);
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const closeButtonRef = useRef(null);


  useEffect(() => {
    getCategoryList();
    getProductList();
  }, []);

  const InputHandler = (e,identify)=>{
    setinquiryData({...inquiryData, [identify]: e.target.value})
  }

  const getCategoryList = () => {
    axiosInstance
      .get("Category/view-category.php")
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (res.code === 100) {
          setCategoryListTotal(res.count);
          setCategoryList(res.data);
        }
      })
      .catch((error) => {
        // Handle any errors
      });
  };

  const getProductList = ()=>{
    axiosInstance
      .post("Product/view-product.php",{
        "priority": "Best-Seller"
      })
      .then((response) => {
        const res = response.data;
        console.log("res:::", res);
        if (res.code === 100) {
          setProductListTotal(res.count);
          setProductList(res.data);
          setloading(false)
        }
      })
      .catch((error) => {
        // Handle any errors
        setloading(false)

      });
  }
  const submitInquiry = (e)=>{
    e.preventDefault();
    console.log('inquiryData',inquiryData);
    const finalData={
      table_name: "product_inquiry",
      is_image: "false",
      data:inquiryData
    }
    axiosInstance
      .post("General/add-data.php",finalData)
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
      })
      .catch((error) => {
        // Handle any errors

      });

  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [{
      breakpoint: 900,
      settings: {
        arrows: true,
        prevArrow: <button type="button" class="slick-arrow slick-prev" style="display: block;"></button>,
        nextArrow: <button type="button" class="slick-arrow slick-next" style="display: block;"></button>,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  const sliderSettings = {
    arrows: false,
    autoplay: true,
    cssEase: 'ease-in-out',
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    speed: 600,
    height:"300px"
  };

  const slides = [
    {
      title: '',
      subtitle: '',
      link: 'category-list/AUTHENTIC-BLENDED-SPICE',
      imageLightMode: isMobile? "assets/images/hero-slider/mobile-1.jpg" :'assets/images/hero-slider/web-1.jpg',
      imageDarkMode: isMobile? "assets/images/hero-slider/mobile-1.jpg" :'assets/images/hero-slider/web-1.jpg',
    },
    {
      title: '',
      subtitle: '',
      link: 'category-list/EASY-TO-COOK',
      imageLightMode: isMobile? "assets/images/hero-slider/mobile-2.jpg":'assets/images/hero-slider/web-2.jpg',
      imageDarkMode: isMobile? "assets/images/hero-slider/mobile-2.jpg":'assets/images/hero-slider/web-2.jpg',
    },
    {
      title: '',
      subtitle: '',
      link: 'category-list/EASY-TO-COOK',
      imageLightMode: isMobile? "assets/images/hero-slider/mobile-3.jpg":'assets/images/hero-slider/web-3.jpg',
      imageDarkMode: isMobile? "assets/images/hero-slider/mobile-3.jpg":'assets/images/hero-slider/web-3.jpg',
    },
  ];

  return (
    <>
      <Header></Header>
      <main id="content" className="wrapper layout-page" style={isMobile?{paddingTop:"130px"}:{paddingTop:"70px"}}>
        <section>
        <Slider className="slick-slider hero hero-header-01" {...sliderSettings}>
          {slides.map((slide, index) => (
            <div key={index} className={`vh-100 d-flex align-items-center ${isMobile?"h-500 pb-11":""}`}>
              <div className="z-index-2 container container-xxl pb-xl-11">
                <div className="hero-content text-start">
                  <div>
                    <h1 className="mb-7 hero-title">{slide.title}
                    <br/>
                    {slide.subtitle && slide.subtitle}
                    </h1>
                  </div>
                  {/* <a
                    href={slide.link}
                    className="btn btn-lg btn-primary btn-hover-border-primary"
                  >
                    Shop Now
                  </a> */}
                </div>
              </div>
              <div
                className={`lazy-bg position-absolute z-index-1 w-100 h-100 light-mode-img ${isMobile?"bg-image-mobile":"bg-overlay"}`}
                data-bg-src={slide.imageLightMode}
              ></div>
              <div
                className={`lazy-bg dark-mode-img position-absolute z-index-1 w-100 h-100 ${isMobile?"bg-image-mobile":"bg-overlay"}`}
                data-bg-src={slide.imageDarkMode}
              ></div>
            </div>
          ))}
        </Slider>
        </section>
        <section className='founder-section py-5 fs-5 pt-0 phighlight'>
          <div className="container container-xxl ">
            <div className="row align-items-center">
              <div className="col-md-6" style={{textAlign: "justify"}}>
                <h2 style={{fontWeight: "bold"}}>VISHESH JOURNEY</h2>
                <p className="text-left"> With over <mark><b>50+ years</b></mark> of experience in the business of Spices (Masala)
                  production, Wankawala family has taken the legacy forward with a brand
                  <mark><b>VISHESH MASALA</b></mark> under the eminent leadership of Mr. Sundeep
                  Wankawala and Mr. Nilesh Wankawala.
                </p>

                <p className="text-left">
                The foundation of Vishesh is based on strong values of simplicity, honest,
                  inspiring and company’s dynamic workforce which is biggest asset that blends a
                  remarkable history and legacy with a visionary growth and innovation.
                </p>
                <a
                 href="/aboutus"
                 className=" py-3 btn btn-lg btn-primary btn-hover-bg-primary btn-hover-border-primary">
                  Know More
                </a>
              </div>
              <div className="col-md-6">
                <div className="img-founder">
                  <img src="./assets/images/banner/journey.jpg" alt="biryani-masala" width={600} />
                </div>
              </div>
            </div> 
          </div>
        </section>
        {(productList && productList.length > 5) && <section className="home-ourproduct-section">
          <div className="container container-xxl pb-16">
            <div className="mb-2 pb-3 text-center">
              <h2 className="mb-5">Best Seller</h2>
              {/* <p className="fs-18px mb-0">The tast of quality</p> */}
            </div>
            <Slider {...settings}>
            {productList.map((prod, index)=>
                 <div>
                 <div
                   className="category-list home-cate-slide d-flex w-100 gap-0 flex-column"
                   
                 >
                   <div className="item fade-up">
                     <figure className="product-image">
                       <a href="#" className="product-link">
                         <img
                           src={`${process.env.REACT_APP_RESOURCE_URL}product_image/${prod.product_image}`}
                           onClick={()=>{setselectedProductIndex(index);setinquiryData({...inquiryData,product:productList[index].product_name, variation:productList[index].product_variations.split(',')[0]})}}
                           data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                           alt="chicken-tikka"
                           width={200}
                         />
                       </a>
                     </figure>
                     <div className="product-detail">
                       <div className="product-name" style={{fontWeight: "bold"}}>{prod.product_name}</div>
                       {/* <button
                         type="button"
                         onClick={()=>{setselectedProductIndex(index);setinquiryData({...inquiryData,product:productList[index].product_name, variation:productList[index].product_variations.split(',')[0]})}}  className="w-100 py-3 btn btn-lg btn-primary btn-hover-bg-primary btn-hover-border-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                         Enquiry
                       </button> */}
                     </div>
                     {/* <ul className="list-inline fs-18px mb-0 category-social-media d-flex flex-column">
                       <li className="list-inline-item">
                         <a href="#" className="icon">
                           <i className="fab fa-twitter" />
                         </a>
                       </li>
                       <li className="list-inline-item">
                         <a href="#" className="icon">
                           <i className="fab fa-facebook-f" />
                         </a>
                       </li>
                       <li className="list-inline-item ">
                         <a href="#" className="icon">
                           <i className="fab fa-instagram" />
                         </a>
                       </li>
                       <li className="list-inline-item me-4">
                         <a href="#" className="icon">
                           <i className="fab fa-youtube" />
                         </a>
                       </li>
                     </ul> */}
                   </div>
                 </div>
               </div>
                )}
            </Slider>
          </div> 
          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Enquiry Now</h5>
                  <button  ref={closeButtonRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form onSubmit={submitInquiry}>
                <div className="modal-body">
                  
                    <div className="mb-8">
                      <label for="recipient-name" className="col-form-label sr-only">Name</label>
                      <input required type="text" onChange={(e)=>InputHandler(e,'name')} value={inquiryData.name && inquiryData.name} className="form-control" id="recipient-name" placeholder='Name' />
                    </div> 
                    <div className="mb-8">
                      <label for="recipient-name" className="col-form-label  sr-only">Phone Number</label>
                      <input required type="tel" pattern="[1-9]{1}[0-9]{9}" onChange={(e)=>InputHandler(e,'phone')}  value={inquiryData.phone && inquiryData.phone} className="form-control" id="recipient-name" placeholder='Phone Number'/>
                    </div> 
                    <div className="mb-8">
                      <label for="recipient-name" className="col-form-label  sr-only">Email</label>
                      <input required type="email" onChange={(e)=>InputHandler(e,'email')}  value={inquiryData.email && inquiryData.email} className="form-control" id="recipient-name" placeholder='Enter Email'/>
                    </div> 
                    <div className="mb-8">
                      <label for="recipient-name" className="col-form-label  sr-only">Product Name</label>
                      <input required type="text" readOnly value={(productListTotal > 0 && selectedProductIndex != -1)&& productList[selectedProductIndex].product_name} className="form-control" id="recipient-name" placeholder='Enter your mail'/>
                    </div> 
                    <div className="mb-8">
                      <label for="recipient-name" className="col-form-label  sr-only">Product Variation</label>
                      <select required onChange={(e)=>InputHandler(e,'variation')} value={inquiryData.variation && inquiryData.variation} className="form-control">
                        {
                          (productListTotal > 0 && selectedProductIndex != -1) &&

                          productList[selectedProductIndex].product_variations.split(',').map((vdata)=>
                        <option value={vdata}>{vdata}</option>

                          )
                        }
                      </select>
                    </div> 
                    <div className="">
                      <label for="recipient-name" className="col-form-label sr-only">City</label>
                      <input required type="text" onChange={(e)=>InputHandler(e,'city')} value={inquiryData.city && inquiryData.city}  className="form-control" id="recipient-name" placeholder='City' />
                    </div> 
                </div>
                <div className="modal-footer pb-8"> 
                  <button type="submit" className="btn btn-primary w-100">Send message</button>
                </div>
                </form>

              </div>
            </div>
          </div>
        </section>}
        {categoryListTotal > 0 && (

        categoryList.map((cate,index)=>{
          if(index % 2 == 0){
           return (
           <section className="image-content-section bg-light-gray py-8 py-md-12">
              <div className="container container-xxl">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1 text-center">
                  <a href={`/category-list/${cate.category_alias}`}>
                    <img
                      src={`${process.env.REACT_APP_RESOURCE_URL}category_icon/${cate.category_icon}`}
                      alt="biryani-masala"
                      width={200}
                    />
                  </a>  
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="content">
                    <a href={`/category-list/${cate.category_alias}`}><h3 className="text-uppercase fw-bold font24 category-title">{cate.category_name}</h3></a>
                      <p className="fs-md-5">
                      {cate.category_description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            )
          }else{
           return (
           <section className="image-content-section py-8 py-md-12">
            <div className="container container-xxl">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="content">
                  <a href={`/category-list/${cate.category_alias}`}><h3 className="text-uppercase fw-bold font24 category-title">{cate.category_name}</h3></a>
                    <p className=" fs-md-5">
                      {cate.category_description}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 text-center">
                <a href={`/category-list/${cate.category_alias}`}>
                  <img
                    src={`${process.env.REACT_APP_RESOURCE_URL}category_icon/${cate.category_icon}`}
                    alt="biryani-masala"
                    width={200}
                  />
                 </a> 
                </div>
              </div>
            </div>
          </section>)
          }
        })
        )}
         
        <div className="coca-section">
          <section
            className="bg-section-5 overflow-hidden pt-15 pb-8 position-relative wave-devider"
            id="specia_offer_beauty_inspired_by_real_life_2"
            style={{background: "#765151"}}
          >
            <div className="container">
              <div className="row call-to-action-2 text-white">
              <h2 className="mb-6 mw-lg-60 pt-1 text-reset text-white mobile-view" style={{color: "#fff !important", textAlign:"center"}}>
                    New Products
                  </h2>
                <div
                  className="col-lg-6 bg-image py-25 py-lg-0 order-lg-1  lazy-bg p-150"
                  data-bg-src="./assets/images/banner/Coco.webp"
                ></div>
                <div className="col-lg-6 ps-6" data-animate="fadeInUp">
                  <div className="py-lg-23 pt-8 pb-10 mt-lg-3 mb-lg-5 ms-lg-auto text-white content-wrap p-o text-center text-lg-start">
                    <div className="text-left">
                      {/* <p className="fs-15px mb-7 ls-1  fw-semibold text-uppercase">
                        Special offer
                      </p> */}
                    <h2 className="mb-6 mw-lg-60 pt-1 text-reset text-white web-view" style={{color: "#fff !important"}}>
                    New Products
                  </h2>
                      <p className="fs-18px mb-0 mw-lg-75 text-white ">
                        Made using clean, non-toxic ingredients, our products
                        are designed for everyone.
                      </p>
                    </div>
                    <a href="/products" className="btn btn-white mt-10 mb-2">
                      Discover Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}
