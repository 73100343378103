import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TimeLine from '../components/TimeLine';

export default function Aboutus() {
  return (
    <>
      <Header></Header>
      <main id="content" className="wrapper layout-page aboutus-page">
        <section className='founder-section py-5 py-md-10 fs-5 phighlight'>
          <div className="container container-xxl ">
            <div className="row align-items-center">
              <div className="col-md-6" >
                <h2>VISHESH JOURNEY</h2>
                <p> With over <mark><b>50+ years</b></mark> of experience in the business of Spices (Masala)
                  production, Wankawala family has taken the legacy forward with a brand
                  <mark><b>VISHESH MASALA</b></mark> under the eminent leadership of Mr. Sundeep
                  Wankawala and Mr. Nilesh Wankawala.
                </p>

                <p>
                  The foundation of Vishesh is based on strong values of simplicity, <mark><b>honest</b></mark>,
                  inspiring and company’s dynamic workforce which is biggest asset that blends a
                  remarkable history and legacy with a visionary <mark><b>growth and innovation</b></mark>.
                </p>
                <p>Vishesh Masala has always remained committed to creating uncompromised
                  quality and exceptional taste of products and continues to expand its circle of
                  committed stakeholders and ever-growing <mark><b>loyal customer base</b></mark>.
                  </p>
                  <p>
                  The product range from the brand has evolved tremendously over the years and
                  its audacious pursuit of ‘Quality & Innovation’ has given a momentum to
                  consumer <mark><b>loyalty and satisfaction</b></mark>. This makes Vishesh Masala one of the most
                  opted brands across demographics in the country.
                  </p>
                  <p>
                  From a modest home grown venture to a brand having state-of-the-art
                  infrastructure, cutting-edge latest technology machineries and path breaking <mark><b>R
                  & D Department</b></mark> for unmatched quality products which is spread over an <mark><b>area of
                  3 acres</b></mark> with 24x7 availability of electricity and water at Navsari, Gujarat.
                </p>
              </div>
              <div className="col-md-6">
                <div className="img-founder" data-animate="fadeInRight">
                  <img src="./assets/images/banner/masala03.png" alt="biryani-masala" width={600} />
                </div>
              </div>
            </div> 
          </div>
        </section>

        <section className='image-content-section fs-5'>
          <div className="container container-xxl ">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-1 pb-5">
              <div className="img-founder" data-animate="fadeInLeft">
                <img src="./assets/images/banner/vision.jpg" alt="Vishesh Masala Founder" width={500} />
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="content" >
                <h3 className='fw-bold font24 mb-0'>VISHESH VISION </h3>
                <p>To become most opted brand of spices, spices blends and seasonings across
                  each food service segments i.e. retail, wholesale, HORECA or E-commerce by
                  consistently providing highest quality value for money products.</p>
              </div>

            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 order-1 order-md-1">
              <div className="content" >
                <h3 className='fw-bold font24 mb-0' style={{marginTop: "40px"}}>VISHESH BRAND PROMISE</h3>
                <p>
                  For us, Quality is The GOD, and it is this no-compromise attitude towards
                  quality that thrives us the confidence to promise our consumers the Best and
                  serve them the Purest.
                </p>
              </div>

            </div>
            <div className="col-md-6 order-2 order-md-2 pb-5">
              <div className="img-founder" data-animate="fadeInRight">
                <img src="./assets/images/banner/masala-banner.png" alt="Vishesh Masala Founder" width={500} />
              </div>
            </div>
          </div>

          </div>
        </section>


        <TimeLine />

      </main>
      <Footer></Footer>
    </>
  );
}
