import React, { useEffect, useState } from "react";
import Footer from '../components/Footer';
import Header from '../components/Header'; 
import Breadcrumbs from '../components/Breadcrumbs';
import axiosInstance from "../axios/axiosInterceptor";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function MainCategoryList() {
const [categoryList, setCategoryList] = useState([]);
const [categoryListTotal, setCategoryListTotal] = useState(0);
const [loading, setloading] = useState(true);

useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    axiosInstance
      .get("Category/view-category.php")
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (res.code === 100) {
          setCategoryListTotal(res.count);
          setCategoryList(res.data);
          setloading(false)
        }
      })
      .catch((error) => {
        // Handle any errors
        setloading(false)
        
      });
  };

  return (
    <>
     <SkeletonTheme >
      <Header></Header>
      {/* <Breadcrumbs category={!loading && productListTotal > 0 && productList[0].category_name} />  */}
      <main id="content" className="wrapper layout-page list-page"> 
        <section className='category-list-wrapper py-10 bg-secondry py-m-5' style={{minHeight: "500px"}}>
          <div className="container container-xxl">
            <div className="category-list"> 
            
              {
                !loading && categoryListTotal > 0 ? (categoryList.map((cate,index)=>{
                  return(
                    <>
                    <div className="item"> 
                      <figure className='product-image '>
                        <a href={`/category-list/${cate.category_alias}`} className='product-link'>
                        <img
                       src={`${process.env.REACT_APP_RESOURCE_URL}category_icon/${cate.category_icon}`} alt="biryani-masala" width={250} />
                        </a>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name"
                      data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                      style={{fontWeight: "bold"}}
                      >
                        {cate.category_name}
                      </div>  
                    </div>
                  </div> 
                  </>
                  )
                  }))
                :loading?
                <>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
                  <div className="item"> 
                      <figure className='product-image '>
                      <Skeleton height={350} width={400}/>
                      </figure> 
                    <div className="product-detail">
                      {/* <div className="product-price">₹ 380.00</div> */}
                      <div className="product-name">
                      <Skeleton />
                      </div>  
                    </div>
                  </div>
              </>
            
                :<p className="text-center">No Categories Found.</p>
              }
            </div>
          </div>


        </section>

      </main>
      <Footer></Footer>
      </SkeletonTheme>
    </>
  );
}
