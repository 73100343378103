import React from 'react';

export default function Footer() {
  return (
    // <footer className=" py-8 footer bg-dark">
    //     <div className="container container-xxl ">
    //         <div className="row ">
    //             <div className="col-lg col-12   mb-lg-0 fs-14px">
    //                 <div className='list-view text-white d-flex flex-sm-row flex-column justify-content-sm-between align-items-center flex-wrap'>
    //                     <p className="mb-0 lh-2 m-0 order-1"> <b className='me-2'>Contact : </b> +91 9499543431 </p>
    //                     <p className='mb-sm-0 mb-4  order-2 order-sm-2'><b className='m-0'>Address : </b> Vishesh house, shed no. 21, doriwala compound, nr. Madhini khamni, udhna, surat-394210 </p> 
    //                     <p className="mb-0 fs-6  text-center order-sm-3 order-3 ">© Dhanhar Vishesh 2023</p>  

    //                 </div>
    //             </div>  
    //         </div>
           

    //     </div>

    //     </footer>
    <footer className="pt-8 pt-lg-10 pb-5 footer bg-section-4">
    <div className="container container-xxl pt-4">
        <div className="row">
        <div className="col-lg col-md-4 col-12 mb-11 mb-lg-0 fs-14px">
            <div>
                <img className="logo-main" src="/assets/images/others/logo.png" alt="logo" width={100} />
            </div>
            <div>
                {/* <img className="logo-main" style={{borderRadius: "10px"}} src="/assets/images/fssai.png" alt="logo" width={150} />
                <img className="logo-main" src="/assets/images/50-years.png" alt="logo" width={150} /> */}
            </div>
        </div>
        <div className="col-lg col-md-4 col-12 mb-11 mb-lg-0">
            <h3 className="fs-5 mb-6 fw-bold">Useful links</h3>
            <ul className="list-unstyled mb-0 fw-medium ">
            <li className="pt-3 mb-4">
                <a href="/products"  className="text-body">Products</a>
            </li>
            <li className="pt-3 mb-4">
                <a href="/aboutus" className="text-body">About Us</a>
            </li>
            <li className="pt-3 mb-4">
                <a href="/contact-us"  className="text-body">Contact Us</a>
            </li>
            {/* <li className="pt-3 mb-4">
                <a href="#" className="text-body">Online Gift Card</a>
            </li> */}
            </ul>
        </div> 
        <div className="col-lg-5 col-12 mb-11 mb-lg-0">
           <div>
           <h3 className="fs-5 mb-6 fw-bold">Dhanhar Masala Bhandar PVT. LTD.</h3>
            <p className="pe-xl-18 lh-2">
            <b className='m-0'><i class="fa-solid fa-location-dot"></i> </b> Vishesh house, shed no. 21, doriwala compound, nr. Madhini khamni, udhna, surat-394210 
            </p>
            <p className="mb-0 lh-2 m-0 order-1"> <b className='me-2'> <i class="fa-solid fa-phone"></i>  </b> +91 9499543431 </p>
           
           </div>
           
            <ul className="list-inline fs-18px mb-0 mt-5">
            {/* <li className="list-inline-item me-8">
                <a href="#"><i className="fab fa-twitter" /></a>
            </li> */}
            <li className="list-inline-item me-8">
                <a target="_blank" href="https://www.facebook.com/Visheshmasala?mibextid=ZbWKwL"><i className="fab fa-facebook-f" /></a>
            </li>
            <li className="list-inline-item me-8">
                <a target="_blank" href="https://instagram.com/vishesh_masala?igshid=OGQ5ZDc2ODk2ZA=="><i className="fab fa-instagram" /></a>
            </li>
            {/* <li className="list-inline-item">
                <a href="#"><i className="fab fa-youtube" /></a>
            </li> */}
            </ul>
        </div>
        </div>
        <div className="fs-6 mt-8 mt-lg-10 text-center">
                <p className="mb-0 text-center">© Dhanhar Masala Bhandar Pvt. Ltd 2023</p> 
       </div> 

    </div>

    </footer>

  );
}
