import axios from 'axios';

const axiosInstance = axios.create({
  // Set your base URL and other configurations here
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['token'] = `dhanhar-admin`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default axiosInstance;