import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import axiosInstance from '../axios/axiosInterceptor';

export default function Contactus() {
  // State variables to hold form data
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState('');

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct form data
    const formData = new FormData();
    formData.append('contact_first', firstName);
    formData.append('contact_last', lastName);
    formData.append('contact_phone', phone);
    formData.append('contact_email', email);
    formData.append('contact_type', type);
    formData.append('contact_subject', subject);
    formData.append('contact_message', message);
    formData.append('contact_attach', attachment);

    axiosInstance
    .post("Contact/insert-contact.php",formData)
    .then((response) => {
      const res = response.data;
      console.log("res", res);
      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      setType('');
      setSubject('');
      setMessage('');
      setAttachment('');
      alert("Inquiry success")
    })
    .catch((error) => {
      // Handle any errors

    });
  };

  return (
    <>
      <Header />
      <main id="content" className="wrapper contactus-page">
        <div className="contact-section py-10 py-md-12">
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h2 className="mb-8">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="contact_first" className="form-label sr-only">First Name</label>
                        <input type="text" className="form-control" id="contact_first" name="contact_first" placeholder='Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="contact_last" className="form-label sr-only">Last Name</label>
                        <input type="text" className="form-control" id="contact_last" name="contact_last" placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="contact_phone" className="form-label sr-only">Number</label>
                        <input type="text" className="form-control" id='contact_phone' name="contact_phone" placeholder='Phone' required value={phone} onChange={(e) => setPhone(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="contact_email" className="form-label sr-only">Your Email</label>
                        <input type="email" className="form-control" id="contact_email" name="contact_email" placeholder='Mail Id' value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="contact_type" className="form-label sr-only">Type</label>
                        <select className="form-control" name='contact_type' id="contact_type" required value={type} onChange={(e) => setType(e.target.value)}>
                          <option>Contact Type</option>
                          <option>Distributorship</option>
                          <option>Career options</option>
                          <option>For Suggestions</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="contact_subject" className="form-label sr-only">Your Subject</label>
                        <input type="text" className="form-control" id="contact_subject" name="contact_subject" placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="contact_message" className="form-label sr-only">Your Message</label>
                        <textarea className="form-control" id="contact_message" name="contact_message" rows="5" placeholder='Your Message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="contact_attach" className="form-label sr-only">Attachment</label>
                        <input type="file" className="form-control" id="contact_attach" name="contact_attach" onChange={(e) => setAttachment(e.target.files[0])} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <button type="submit" className="btn btn-primary w-100 fw-bold">Send</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
