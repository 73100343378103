import React from 'react';
import "../scss/components/timeline.scss";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
export default function TimeLine() {
	return (
		<>


			<section className='bg-secondry py-8 overflow-hidden'>
				<div className="container">
				<div className="text-center  pb-10 fs-18px ">
					<h2 className='py-md-10 py-5  fw-bold'>
						Our Journey
					</h2>
					<div className="img-founder pb-5">
						<img src="./assets/images/banner/vishesh-founder-1.png" alt="biryani-masala" className='shadow-lg p-3 mb-5 bg-body rounded rounded-3' width={300} />
					</div>
					<div className='phighlight'>
					<h4 className='pb-5' style={{color: "#000"}}>LATE SHRI JAGDISHCHANDRA ISHWARLAL WANKAWALA</h4>
					<p className='pt-5 ' >
						Driven by vision, passion and desire to create a mark in the world of Spices, Vishesh Masala
						formerly known as Dhanhar Masala was incepted from a very humble beginning in the early
						70's, founded by young B. Sc. (Microbiology) and D.T.C. graduate from M.S. University,
						Vadodara LATE SHRI JAGDISHCHANDRA ISHWARLAL WANKAWALA.
					</p>
					<p >
						Initially he joined his family business of spices shop which was under the name of
						Dhansukhlal Harkishandas & Sons at Surat,from which <mark><b>Dhanhar</b></mark> brand was depited. 
					</p>
					<p >
						By delivering excellence in quality products, he grew from strength to strength achieving one
						milestone at a time. His discipline, honesty, passion & empathy ingested since the early days
						of his work, reflects even today in the company's culture and that, we believe, is our constant
						achievement
					</p>
					</div>
				</div>

				</div>
				<div className="timeline-wrapper">
					<VerticalTimeline>
						<VerticalTimelineElement
							className="vertical-timeline-element--work"
							contentArrowStyle={{ borderRight: '7px solid #fff' }}
							date="1970"
							iconStyle={{ color: '#fff' }}

						>
							<p>
								Our story of manifestation of dreams & aspirations started with a small rental
								production unit in Udhna, Surat an epicentre of manufacturing. Hing (Asafoetida) was the
								first product developed by us having a tin pack of 15 kgs and 5 kgs in loose.
							</p>
						</VerticalTimelineElement>

						<VerticalTimelineElement
							className="vertical-timeline-element--work"
							date="1971"
							iconStyle={{ color: '#fff' }}

						>
							<p>
								The Company expanded its infrastructure and started another production
								unit in Katargam, Surat. For the first 5 years there was only manufacturing of
								Hing undertaken at the facility.
							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--work"
							date="1976"
							iconStyle={{ color: '#fff' }}
						>
							<p>
								To be the first mover in the market, we started manufacturing of blended
								spices. The first product introduced in masala was Dalshak Masala which was
								known as Laxmi Chaap Masala during those days. We use to sell our product
								in bulk packaging at Mom-and-Pop shops and the delivery of goods used to be
								in a very traditional and economical way with the help of bullock cart.
							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--work"
							date="1988"
							iconStyle={{ color: '#fff' }}

						>
							<p>
								The introduction of young Gen-next to the core management with a
								second line of leadership came in the business, Shri Sundeep Wankawala and
								Nilesh Wankawala.

							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--education"
							date="1990"
							iconStyle={{ color: '#fff' }}

						>

							<p>
								A major milestone was achieved by the company by getting its first <br />
								aggmark certification for its Hing & other spices.
							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--education"
							date="1991"
							iconStyle={{ color: '#fff' }}

						>

							<p>
								Started penetrating spices in market with the packing size of 50g, 100g,
								200g and 500g. The first product introduced under the name of Dhanhar Masala
								brand were Aggmark Hing (now known as Premium Hing) and Rajwadi Garam
								Masala.

							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--education"
							date="1996"
							iconStyle={{ color: '#fff' }}

						>

							<p>
								With a vision of expanding our horizons, we introduced Dealership
								Model and started distribution channel which also helped us in enlarging our
								product portfolio.
							</p>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className="vertical-timeline-element--education phighlight"
							date="2009"
							iconStyle={{ color: '#fff' }}

						>
							<p>
							Split between family promoters of Dhanhar Masala, Shri Sundeep & Shri
							Nilesh Wankawala with all hard-work and their gamut of experience and sheer
							values introduced a new brand <mark><a href="#"><b> Vishesh Masala</b></a></mark>.

							</p>
						</VerticalTimelineElement>
						
						<VerticalTimelineElement
							className="vertical-timeline-element--education"
							date="2021 - Present"
							iconStyle={{ color: '#fff' }}

						>
							<p>
							With all the grace of almighty The God, all the elders, well wishers,
							supplier brothers and always on-toes team members, shifted manufacturing unit
							to Navsari, Gujarat with all the state-of-the-art manufacturing facility and latest
							technology machineries.

							</p>
						</VerticalTimelineElement>


					</VerticalTimeline>

				</div>
			</section>

		</>
	);
}
